import React from 'react';

import GettingStartedPage from '../components/GettingStartedPage';


const getstated= () => {

  return (<>
    <GettingStartedPage />
  </>
  );

};

export default getstated;
