import React from 'react';
import PageContainer from './PageContainer';
import Header from '../Shared/Header/HeaderContainer';
import Footer from '../Shared/Footer/FooterContainer';
import { ThemeProvider } from '../Shared/ThemeProvider/index';

const GettingStated = () => {
  return (
    <>
      <ThemeProvider>
        <Header lightLogo={true}/>
        <PageContainer />
        <Footer/>
      </ThemeProvider>
    </>
  );
};

export default GettingStated;