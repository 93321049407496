// index.js
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useTheme } from '@mui/material/styles';
import image2 from '../Images/image2.png';
import signup from '../Images/sign-up(2).png';
import PatImage from '../Images/token2.png';
import apiimage from '../Images/t3.png';
import {
  Container,
  Typography,
  Paper,
  Grid,
  Avatar,
  Divider,
  Box,
} from '@mui/material';

const PageContainer = () => {
  const { t, i18n } = useTranslation();

  const marginTopStyle = {
    marginTop: '20px',
  };
  const changeLang = (lang) => {
    i18n.changeLanguage(lang);
  };

  const customTheme = useTheme();
  const background = customTheme.palette.mode === 'light' ? 'white' : '#1f1f1f';

  return (
    <><Box sx={{ backgroundColor: { background } }}>
      <Container>
        <Typography>
          <Paper sx={{ padding: 3, backgroundColor: { background }, boxShadow: 'none' }}>
            <Typography variant="h3" gutterBottom style={{ marginTop: 20, fontWeight: 500 }}>
              {t('topPage.title')}
            </Typography>
            <Typography variant="h5" gutterBottom style={{ marginTop: 20 }}>
              {t('topPage.guide')}
            </Typography>
            <Divider sx={{ marginBottom: 3, borderColor: 'white' }} />
            <Grid container spacing={2}>
              {/* Section 1 login */}
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <Avatar src={signup} alt="signup" sx={{ width: '50%', height: 'auto' }} />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <Paper sx={{ ...marginTopStyle, boxShadow: 'none' }}>
                  <Typography sx={{ backgroundColor: { background } }}>
                    <Typography variant="h4" sx={{ backgroundColor: { background } }}>
                      1.{t('topPage.logina')}                
                    </Typography>
                    <Typography paragraph sx={marginTopStyle}>
                      {t('topPage.loginb')}
                    </Typography>
                    <Typography paragraph sx={marginTopStyle}>
                      {t('topPage.loginc')}
                    </Typography>

                  </Typography>
                </Paper>
              </Grid>

              {/* Section 2 create application */}
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <Paper sx={{ ...marginTopStyle, boxShadow: 'none' }}>
                  <Typography sx={{ backgroundColor: { background } }}>
                    <Typography variant="h4">
                        2.   {t('topPage.createa')}
                    </Typography>
                    <Typography paragraph sx={marginTopStyle}>
                      {t('topPage.createb')}
                    </Typography>
                    <Typography paragraph sx={marginTopStyle}>
                      {t('topPage.createc')}
                    </Typography>
                    <Typography paragraph sx={{ ...marginTopStyle }}>
                      <strong>  {t('topPage.loginba')}</strong>  {t('topPage.created')}
                    </Typography>
                    <Typography paragraph sx={marginTopStyle}>
                      <strong>  {t('topPage.loginbb')}</strong>   {t('topPage.createe')}
                    </Typography>
                    <Typography paragraph sx={marginTopStyle}>
                      <strong>  {t('topPage.loginbc')}</strong>   {t('topPage.createf')}
                    </Typography>
                    <Typography paragraph sx={marginTopStyle}>
                      <strong>  {t('topPage.loginbd')}</strong>{t('topPage.createg')}
                    </Typography>
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <Avatar src={image2} alt="Man With Computer" sx={{ width: '80%', height: 'auto' }} />
              </Grid>

              {/* Section 3 PAT */}
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <Avatar src={PatImage} alt="Man With Computer" sx={{ width: '80%', height: 'auto' }} />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <Paper sx={{ ...marginTopStyle, boxShadow: 'none' }}>
                  <Typography sx={{ backgroundColor: { background } }}>
                    <Typography variant="h4">
                        3. 
                      {t('topPage.pate')}
                    </Typography>
                    <Typography paragraph sx={marginTopStyle}>
                      {t('topPage.pata')}
                        
                    </Typography>
                    <Typography paragraph sx={marginTopStyle}>
                      {t('topPage.patb')}
                       
                    </Typography>
                    <Typography paragraph sx={marginTopStyle}>
                      {t('topPage.patc')}
                      
                    </Typography>
                    <Typography paragraph sx={marginTopStyle}>
                      {t('topPage.patd')}
                      
                    </Typography>
                  </Typography>
                </Paper>
              </Grid>

              {/* Section 4 API request */}
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <Paper sx={{ marginTop: '80px', boxShadow: 'none' }}>
                  <Typography sx={{ backgroundColor: { background } }}>
                    <Typography variant="h4">
                      
                       4. {t('topPage.apirequest')}
                    </Typography>
                    <Typography paragraph sx={marginTopStyle}>
                      {t('topPage.accesstoken')}
                    </Typography>
                    <Typography paragraph sx={marginTopStyle}>
                      {t('topPage.tools')}
                    </Typography>
                    <Typography paragraph sx={marginTopStyle}>
                      {t('topPage.token')}
                    </Typography>
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <Avatar src={apiimage} alt="Man With Computer" sx={{ width: '80%', height: 'auto' }} />
              </Grid>
              {/* Section 5 man standing on ladder */}
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <Avatar src="https://developer.dnb.no/new/Overview.676c0a78bf8392e3a4ba.svg" alt="A person standing on a ladder and looking out" sx={{ width: '100%', height: 'auto' }} />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <Paper sx={{ ...marginTopStyle, boxShadow: 'none' }}>
                  <Typography sx={{ backgroundColor: { background } }}>
                    <Typography paragraph sx={marginTopStyle}>
                      {t('topPage.following')}
                    </Typography>
                    <Typography variant="h4">
                      <Typography paragraph sx={marginTopStyle}>
                        {t('topPage.email')}
                        <a href="mailto:api@solibri.com"> api@solibri.com</a>
                      </Typography>
                    </Typography>
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
          </Paper>

        </Typography>
      </Container>
    </Box></>
  );
};

export default PageContainer;